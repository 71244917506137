<template>
  <div class="users-table">
    <v-simple-table>
      <thead>
        <tr class="users-table__header">
          <th />
          <th>First Name</th>
          <th>Last Name</th>
          <th>Email</th>
          <th>Company</th>
          <th>User Type</th>
          <th>Created Date</th>
          <th>Last login</th>
          <th />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in users" :key="i">
          <td class="users-table__header">
            <img
              v-if="item.gravatarUrl"
              height="40px"
              :src="item.gravatarUrl"
              class="img-avatar"
            >
            <v-icon v-else color="white">
              mdi-account-outline
            </v-icon>
          </td>
          <td class="users-table__header">
            {{ item.firstName }}
          </td>
          <td class="users-table__header">
            {{ item.lastName }}
          </td>
          <td class="users-table__header">
            {{ item.email }}
          </td>
          <td class="users-table__header">
            {{ item.company }}
          </td>
          <td class="users-table__header">
            {{ item.isPulseAdmin ? 'Admin' : 'User' }}
          </td>
          <td class="dashboards-table__text" :title="getDateWithDayNameAndTime(item.createdAt)">
            {{ `${getDateWithMonthName(item.createdAt)}` }}
          </td>
          <td class="dashboards-table__text" :title="getDateWithDayNameAndTime(item.lastLogin)">
            {{ `${getDateWithMonthName(item.lastLogin)}` }}
          </td>
          <td class="v-menu-attach-parent">
            <v-menu
              v-if="optionListItems.length"
              nudge-top="-5px"
              nudge-left="15px"
              :offset-y="true"
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  class="elevation-0 outlined-btn px-12"
                  height="34px"
                  outlined
                  plain
                  v-bind="attrs"
                  v-on="on"
                  @click="$emit('click')"
                >
                  Actions
                </v-btn>
              </template>
              <OuterList
                title="Actions"
                :items="optionListItems(item)"
                @click="$emit('action', { event: $event, item })"
              />
            </v-menu>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";
import OuterList from "@/sharedComponents/OuterList";

export default {
  name: "UsersTable",
  components: { OuterList },
  mixins: [datesMixin],
  props: {
    users: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    optionListItems(item) {
      if (this.$store.getters['user/isPulseAdmin']) {
        let list = [{ label: "Edit item", icon: "mdi-pencil", value: "edit" }]
        if (!item.isVerified) {
          list.push({
            label: "Delete Item",
            icon: "mdi-delete-outline",
            value: "delete",
          })
        }
        if (item.failedAttempts > 0) {
          list.push({ label: "Unlock User", icon: "mdi-lock-open", value: "reset-attempts" })
        }
        return list;
      }

      return [];
    },
  },
};
</script>

<style lang="scss" scoped>
.users-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
    }
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }

  thead {
    tr > th {
      border-bottom: solid 1px #dee7ee !important;
    }
  }
  tbody {
    // table border colors
    tr {
      height: 77px !important;
      td {
        border-bottom: solid 1px #dee7ee;
      }
    }
    tr:hover {
      background: inherit !important;
    }
  }
  img.img-avatar {
    border-radius: 50%;
    object-fit: contain;
  }
}
</style>
