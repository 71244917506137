<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      header-text="Manage Users"
      class="mb-7 px-3"
      :regular-button-text="
        $store.getters['user/isPulseAdmin'] ? 'NEW USER' : null
      "
      :regular-button-visible="$store.getters['user/isPulseAdmin']"
      @regular-button-click="$router.push({name: 'NewUser'})"
    />

    <SearchFieldRowWithPagination
      :current-page-number="users_page_number"
      :current-page-size="users_page_size"
      :count-total="users_total"
      :page-sizes="[25, 50, 100]"
      :init-search-string="search_string"
      search-field-placeholder="Search for users"
      class="ml-1 ml-md-0 mb-4 mb-md-0"
      @search="(keyword) => get_available_users(null, keyword)"
      @next-page="get_users_next_page"
      @prev-page="get_users_prev_page"
      @change-page-size="users_change_page_size"
    >
      <div class="mr-4">
        <v-menu
          v-model="companyMenuOpened"
          nudge-top="-5px"
          :offset-y="true"
        >
          <template #activator="{ on, attrs }">
            <div
              class="simple-text-button button--blue"
              v-bind="attrs"
              v-on="on"
              @click="$emit('click')"
            >
              Filter by Company
            </div>
          </template>
          <OuterList
            title="Filter by Company"
            :items="user_companies"
            @click="filterByCompany($event)"
          />
        </v-menu>
      </div>
      <div class="mr-4">
        <v-menu
          v-model="accountMenuOpened"
          nudge-top="-5px"
          :offset-y="true"
        >
          <template #activator="{ on, attrs }">
            <div
              class="simple-text-button button--blue"
              v-bind="attrs"
              v-on="on"
              @click="$emit('click')"
            >
              Filter by Account
            </div>
          </template>
          <OuterList
            title="Filter by Account"
            :items="user_accounts"
            @click="filterByAccount($event)"
          />
        </v-menu>
      </div>
      <div>
        <v-menu
          v-model="lastLoginMenuOpened"
          nudge-top="-5px"
          :offset-y="true"
        >
          <template #activator="{ on, attrs }">
            <div
              class="simple-text-button button--blue"
              v-bind="attrs"
              v-on="on"
              @click="$emit('click')"
            >
              Filter by Last login
            </div>
          </template>
          <OuterList
            title="Filter by Last login"
            :items="lastLoginItems"
            @click="filterByLastLogin($event)"
          />
        </v-menu>
      </div>
    </SearchFieldRowWithPagination>

    <p class="scroll-title px-4 d-md-none">
      You can scroll in the table to see more information of users
    </p>

    <UsersTable
      :users="users"
      class="px-3"
      @action="handleAction($event)"
    />

    <v-dialog v-model="delete_dialog" scrollable max-width="360px" @click:outside="edit_item_id = null">
      <DeleteConfirmationDialog
        v-if="delete_dialog"
        header="Delete User"
        subheader="Do you really want to delete user?"
        action_button_name="Confirm"
        @delete="delete_user"
        @dismiss="delete_dialog = false; edit_item_id = null;"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import UsersTable from "@/views/Users/components/UsersTable";
import DeleteConfirmationDialog from "@/sharedComponents/DeleteConfirmationDialog";
import SearchFieldRowWithPagination from "@/sharedComponents/SearchFieldRowWithPagination";
import OuterList from "@/sharedComponents/OuterList";

export default {
  name: "ManageUserList",
  metaInfo: {
    title: 'Manage Users'
  },
  components: {
    OuterList,
    SearchFieldRowWithPagination,
    PageHeader,
    UsersTable,
    DeleteConfirmationDialog,
  },
  data() {
    return {
      users: [],
      delete_dialog: false,
      edit_item_id: null,
      users_total: 0,
      users_page_number: 1,
      users_page_size: 25,
      search_string: '',
      companyMenuOpened: false,
      accountMenuOpened: false,
      lastLoginMenuOpened: false,
      company: null,
      accountId: null,
      lastLoginPeriod: null,
      user_companies: [],
      user_accounts: [],
      lastLoginItems: [
        {
          label: "60 days",
          value: 60,
          selected: false,
        },
        {
          label: "90 days",
          value: 90,
          selected: false,
        },
        {
          label: "120 days",
          value: 120,
          selected: false,
        },
      ],
    };
  },
  created() {
    this.get_available_users();
    this.get_accounts();
    this.get_companies();
  },
  methods: {
    async users_change_page_size(size) {
      this.users_page_size = size;
      this.users_page_number = 1;
      await this.get_available_users();
    },
    async get_users_next_page() {
      this.users_page_number += 1;
      await this.get_available_users();
    },
    async get_users_prev_page() {
      this.users_page_number -= 1;
      await this.get_available_users();
    },
    async get_available_users(params, keyword) {
      if (!params) {
        params = {
          page: this.users_page_number,
          limit: this.users_page_size,
          sort: ['firstName:asc', 'lastName:asc'],
        };
      }
      this.search_string = keyword;

      if (this.company && this.company !== 'All companies') {
        params.company = this.company;
      }

      if (this.accountId) {
        params.accountId = this.accountId;
      }

      if (this.search_string) {
        if (this.validateEmail(this.search_string)) {
          params.email = this.search_string;
        } else {
          params.name = this.search_string;
        }
      }

      if (this.lastLoginPeriod) {
        params.lastLoginPeriod = this.lastLoginPeriod;
      }

      const resp = await this.$rest.admin.get_collection(params);
      this.users = resp.data.items;
      this.users_total = resp.data.totalCount;
    },
    async handleAction(action) {
      if (action.event.value === "delete") {
        this.edit_item_id = action.item.id
        this.delete_dialog = true
      } else if(action.event.value === "edit") {
        await this.$router.push({name: 'ManageUser', params: { id: action.item.id }})
      } else if(action.event.value === "reset-attempts") {
        await this.$rest.admin.reset_login_attempts(action.item.id)
      }
    },
    async handleFilter(filters) {
      await this.get_available_users(filters);
    },
    async delete_user() {
      await this.$rest.admin.delete_resource(this.edit_item_id);
      this.delete_dialog = false;

      this.$store.commit('snackbar/showMessage', {
        color: "info",
        content: "Successfully deleted the user.",
      });

      await this.get_available_users({});
    },
    async get_companies() {
      let resp = (await this.$rest.user.get_user_companies()).data;
      resp.unshift('All companies')
      this.user_companies = resp.map((item) => ({
        label: item,
        value: item,
        selected: false,
      }));
    },
    async get_accounts() {
      let resp = (await this.$rest.account.get_collection({
        ignorePagination: true,
        fields: [
          'name',
          'accountId',
        ]
      })).data.items;
      resp.unshift({name: 'All accounts', accountId: null})
      this.user_accounts = resp.map((item) => ({
        label: item.name,
        value: item.accountId,
        selected: false,
      }));
    },
    async filterByCompany(status){
      let elem = this.user_companies.filter( i => i.value === status.value)[0];
      elem.selected = !elem.selected;
      this.user_companies.filter( i=> i.value !== status.value).forEach(i => i.selected = false);

      this.company = status.value;
      await this.get_available_users();
    },
    async filterByAccount(status){
      let elem = this.user_accounts.filter( i => i.value === status.value)[0];
      elem.selected = !elem.selected
      this.user_accounts.filter( i=> i.value !== status.value).forEach(i => i.selected = false);
      this.accountId = status.value;
      await this.get_available_users();
    },
    async filterByLastLogin(status) {
      let elem = this.lastLoginItems.filter(i => i.value === status.value)[0];
      elem.selected = !elem.selected;
      this.lastLoginItems.filter( i=> i.value !== status.value).forEach(i => i.selected = false);
      this.lastLoginPeriod = status.value;
      await this.get_available_users();
    },
    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint, .scroll-title {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}
::v-deep .field__subheader {
  font-size: 15px !important;
  font-weight: 600 !important;
  color: #241c15;
}
::v-deep .v-input--radio-group__input {
  .v-label {
    font-size: 13px !important;
  }
}
</style>
