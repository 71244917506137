var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-3 px-sm-10 px-0 elevation-0",attrs:{"fluid":""}},[_c('PageHeader',{staticClass:"mb-7 px-3",attrs:{"header-text":"Manage Users","regular-button-text":_vm.$store.getters['user/isPulseAdmin'] ? 'NEW USER' : null,"regular-button-visible":_vm.$store.getters['user/isPulseAdmin']},on:{"regular-button-click":function($event){return _vm.$router.push({name: 'NewUser'})}}}),_c('SearchFieldRowWithPagination',{staticClass:"ml-1 ml-md-0 mb-4 mb-md-0",attrs:{"current-page-number":_vm.users_page_number,"current-page-size":_vm.users_page_size,"count-total":_vm.users_total,"page-sizes":[25, 50, 100],"init-search-string":_vm.search_string,"search-field-placeholder":"Search for users"},on:{"search":function (keyword) { return _vm.get_available_users(null, keyword); },"next-page":_vm.get_users_next_page,"prev-page":_vm.get_users_prev_page,"change-page-size":_vm.users_change_page_size}},[_c('div',{staticClass:"mr-4"},[_c('v-menu',{attrs:{"nudge-top":"-5px","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"simple-text-button button--blue",on:{"click":function($event){return _vm.$emit('click')}}},'div',attrs,false),on),[_vm._v(" Filter by Company ")])]}}]),model:{value:(_vm.companyMenuOpened),callback:function ($$v) {_vm.companyMenuOpened=$$v},expression:"companyMenuOpened"}},[_c('OuterList',{attrs:{"title":"Filter by Company","items":_vm.user_companies},on:{"click":function($event){return _vm.filterByCompany($event)}}})],1)],1),_c('div',{staticClass:"mr-4"},[_c('v-menu',{attrs:{"nudge-top":"-5px","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"simple-text-button button--blue",on:{"click":function($event){return _vm.$emit('click')}}},'div',attrs,false),on),[_vm._v(" Filter by Account ")])]}}]),model:{value:(_vm.accountMenuOpened),callback:function ($$v) {_vm.accountMenuOpened=$$v},expression:"accountMenuOpened"}},[_c('OuterList',{attrs:{"title":"Filter by Account","items":_vm.user_accounts},on:{"click":function($event){return _vm.filterByAccount($event)}}})],1)],1),_c('div',[_c('v-menu',{attrs:{"nudge-top":"-5px","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"simple-text-button button--blue",on:{"click":function($event){return _vm.$emit('click')}}},'div',attrs,false),on),[_vm._v(" Filter by Last login ")])]}}]),model:{value:(_vm.lastLoginMenuOpened),callback:function ($$v) {_vm.lastLoginMenuOpened=$$v},expression:"lastLoginMenuOpened"}},[_c('OuterList',{attrs:{"title":"Filter by Last login","items":_vm.lastLoginItems},on:{"click":function($event){return _vm.filterByLastLogin($event)}}})],1)],1)]),_c('p',{staticClass:"scroll-title px-4 d-md-none"},[_vm._v(" You can scroll in the table to see more information of users ")]),_c('UsersTable',{staticClass:"px-3",attrs:{"users":_vm.users},on:{"action":function($event){return _vm.handleAction($event)}}}),_c('v-dialog',{attrs:{"scrollable":"","max-width":"360px"},on:{"click:outside":function($event){_vm.edit_item_id = null}},model:{value:(_vm.delete_dialog),callback:function ($$v) {_vm.delete_dialog=$$v},expression:"delete_dialog"}},[(_vm.delete_dialog)?_c('DeleteConfirmationDialog',{attrs:{"header":"Delete User","subheader":"Do you really want to delete user?","action_button_name":"Confirm"},on:{"delete":_vm.delete_user,"dismiss":function($event){_vm.delete_dialog = false; _vm.edit_item_id = null;}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }